<template>
  <component :is="tag" ref="wrapper">
    <slot />
  </component>
</template>

<script lang="ts" setup>
/**
 * A wrapper component that handles keyboard navigation inside the provided
 * slot children.
 *
 * The slot must directly contain a list of elements that are either focusable
 * or contain one focusable element (such as <input>, <a> or <button>). The
 * component handles arrow keys (up and down) to focus through the list. It
 * also listens to pressing the space key, which will trigger a click on the
 * element.
 */

const props = withDefaults(
  defineProps<{
    tag?: string
    isEnabled?: boolean
  }>(),
  { tag: 'div', isEnabled: true },
)

const emit = defineEmits(['escape'])

const wrapper = ref<HTMLDivElement | null>(null)

/**
 * Find the focusable element inside the element. Returns the element itself
 * if none are found.
 */
function getFocusableElement(el: HTMLElement): HTMLElement {
  const focusableChild = el.querySelector('a, button, input')
  if (focusableChild instanceof HTMLElement) {
    return focusableChild
  }
  return el
}

/**
 * Handles pressing arrow down and up inside the slot children.
 */
function onKeyDown(e: KeyboardEvent) {
  if (!wrapper.value || !props.isEnabled) {
    return
  }

  if (e.code === 'Escape') {
    return emit('escape')
  }

  if (e.code === 'ArrowUp' || e.code === 'ArrowDown' || e.code === 'Space') {
    const children = [...wrapper.value.children]

    // The currently focused element.
    const focused = children.find((child) => {
      return (
        child === document.activeElement ||
        child.contains(document.activeElement)
      )
    })

    // If no element is focused, focus the first on in the list.
    if (!focused && e.code !== 'Space') {
      const first = children[0]
      if (first && first instanceof HTMLElement) {
        getFocusableElement(first).focus()
        e.preventDefault()
      }
      return
    }

    if (focused instanceof HTMLElement) {
      e.preventDefault()
      if (e.code === 'ArrowUp') {
        const prev = focused.previousElementSibling
        if (prev && prev instanceof HTMLElement) {
          getFocusableElement(prev).focus()
        }
      } else if (e.code === 'ArrowDown') {
        const next = focused.nextElementSibling
        if (next && next instanceof HTMLElement) {
          getFocusableElement(next).focus()
        }
      } else if (e.code === 'Space') {
        focused.click()
      }
    }
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeyDown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDown)
})
</script>

<script lang="ts">
export default {
  name: 'KeyboardNavigationProvider',
}
</script>
